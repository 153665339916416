import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";
import { Primary } from "../../components/Section";

import Gallery from "react-photo-gallery";

import Zen01 from "../../images/zen/zen-01.jpg";
import Zen02 from "../../images/zen/zen-02.jpg";
import Zen03 from "../../images/zen/zen-03.jpg";
import Zen04 from "../../images/zen/zen-04.jpg";

const photos = [
  {
    src: Zen01,
    width: 4.8,
    height: 6.4,
  },
  {
    src: Zen02,
    width: 4.8,
    height: 6.4,
  },
  {
    src: Zen03,
    width: 4.8,
    height: 6.4,
  },
  {
    src: Zen04,
    width: 4.2,
    height: 3.3,
  },
];

const Zen = () => (
  <section>
    <Primary centered>
      <Row>
        <Col xs={12}>
          <h2>Zen</h2>
          <p>The MJ MD Global Management Newfoundland!</p>
          <br />
          <Gallery photos={photos} />
        </Col>
      </Row>
    </Primary>
  </section>
);

export default Zen;
