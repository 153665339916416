import React from "react";

import Layout from "../components/Layout";

import Zen from "../components/Zen";

const ZenPage = ({ data }) => (
  <Layout>
    <Zen data={data} />
  </Layout>
);

export default ZenPage;
